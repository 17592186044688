import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
const imagesLoaded = require('imagesloaded');

import Fade from './transitions/fade';
import DefaultView from './views/default';
import EventsView from './views/events';

/**
 * SASS
 */
import '../sass/app.scss';


const H = new Highway.Core({
    renderers: {
      default: DefaultView,
      events: EventsView,
    },
      transitions: {
      default: Fade
    }
});

/**
 * JavaScript
 */
imagesLoaded( document.querySelector('body'), function( instance ) {
    /*
    shows DOM after all images loaded
    */
    console.log('all images are loaded');
    // gsap.set('.fairy svg path', {fill: '#973973'})
    gsap.set('.fairy .grad, header, main, footer', {opacity: 0})

    if(document.body.classList.contains('is-mobile')){
      gsap.to('.fairy-cursor, .grad', {
        x:'-39vw',
        y:'-43vh',
        opacity: 0,
        delay: 1,
        ease: "power4.out"
      });
    }
    gsap.to('.fairy svg path', {fill: '#fff', duration: 1.5, delay: .5, ease: "power4.inOut"});
    gsap.to('.fairy .grad, header, main, footer', {opacity: 1, duration: 1, delay: 1.2})
    /*
    Remove section body_class
    */
    document.querySelector('main > section').className = '';
});

import axios from "axios";

class RegistrationForm{
	constructor() {
		this.data = {            
			form: document.querySelectorAll('.event-form'),
		};
		this.init();
	}
  
	init() {
    this.data.form.forEach(element => {
      element.onsubmit = (e) => {
        // PREVENT EVENT FORMS 
        e.preventDefault();
        const form = element;
        const formData = new FormData(form);
        
        // CALL ENDPOINT ACTION AND SEND FORM DATA
        axios({
          method: "post",
          url: window.location.protocol + "//" + window.location.hostname + "/wp-admin/admin-ajax.php?action=sendEventFromRegistration",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response.data)
                  
          element.querySelector("button").style.opacity = ".5";
          element.querySelector("button").style.pointerEvents = "none";
          if(response.data == 'exists'){     
            // IF EMAIL EXISTS                 
            element.querySelector('.response').style.opacity = '1';
            element.querySelector('.response').innerHTML = 'Email already registered!';
          }
          else if(response.data != 'error'){
            // IF RESPONSE OK
            element.querySelector('.response').style.opacity = '1';
            element.querySelector('.response').innerHTML = 'You are registered to the event! See you there!';           
          }
          else{
            // IF ERROR
            element.querySelector('.response').innerHTML = 'Error, please try again later!';
            element.querySelector('.response').style.opacity = '1';            
          }			
          
          setTimeout(() => {
            // ALLOW FORM INTERACTION AGAIN
            element.querySelector("button").style.opacity = "1";
            element.querySelector("button").style.pointerEvents = "all";
            element.querySelector('.response').style.opacity = '0';
            element.querySelector('.response').innerHTML = '';
          }, 2000);
        })
        .catch((response) => {
          //handle error
          console.log(response);
          document.querySelector('.response').innerHTML = 'Error';
          document.querySelector('.response').style.opacity = '1';
          setTimeout(() => {
            document.querySelector('.response').style.opacity = '0';
          }, 2000);
        })
      }
    });		
	}

}


export default RegistrationForm;
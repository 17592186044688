import axios from "axios";

class SignupForms{
	constructor() {
		this.data = {            
			form: document.querySelector('.signup-form'),
		};
 
		// this.handlerSubmit = this.handlerSubmit.bind(this);

		this.init();
	}
  
	init() {
    
    if(document.querySelector('.signup-form')){
      document.querySelectorAll('.signup-form').forEach(element => {
        element.onsubmit = (e) => {
          e.preventDefault();
          const form = element;
          const formData = new FormData(form);
          
          axios({
            method: "post",
            url: window.location.protocol + "//" + window.location.hostname + "/wp-admin/admin-ajax.php?action=signupForm",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            console.log(response.data)
                    
            element.querySelector("button").style.opacity = ".5";
            element.querySelector("button").style.pointerEvents = "none";
            if(response.data != 'error'){
              element.querySelector('.response').style.opacity = '1';
              element.querySelector('.response').innerHTML = 'You just signed up! Thank you!';           
            }
            else{
              element.querySelector('.response').innerHTML = 'Error, please try again later!';
              element.querySelector('.response').style.opacity = '1';            
            }			
            
            setTimeout(() => {
              element.querySelector("button").style.opacity = "1";
              element.querySelector("button").style.pointerEvents = "all";
              element.querySelector('.response').style.opacity = '0';
              element.querySelector('.response').innerHTML = '';
            }, 2000);
          })
          .catch((response) => {          
            console.log(response);          
          })
        }
      });	
    }    	
    else if(document.querySelector('.contact-form')){      
      let element = document.querySelector('.contact-form');
      element.onsubmit = (e) => {
        e.preventDefault();
        const form = element;
        const formData = new FormData(form);
        element.querySelector("button").style.opacity = ".5";
        element.querySelector("button").style.pointerEvents = "none";
        axios({
          method: "post",
          url: window.location.protocol + "//" + window.location.hostname + "/wp-admin/admin-ajax.php?action=signupForm",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response.data)                    
          if(response.data != 'error'){
            element.querySelector('.response').style.opacity = '1';
            element.querySelector('.response').innerHTML = '<br/>Thanks for reaching out to PomPom Fairy! Our magicians are reviewing your request and will get back to you shortly. If you have any further questions or comments, contact us: <a href="mailto:contact@pompomfairy.com">contact@pompomfairy.com</a>';           
          }
          else{
            element.querySelector('.response').innerHTML = 'Error, please try again later!';
            element.querySelector('.response').style.opacity = '1';
          }			
          
          // setTimeout(() => {
          //   element.querySelector("button").style.opacity = "1";
          //   element.querySelector("button").style.pointerEvents = "all";
          //   element.querySelector('.response').style.opacity = '0';
          //   element.querySelector('.response').innerHTML = '';
          // }, 2000);
        })
        .catch((response) => {          
          console.log(response);          
        })
      }
    }
	}

}


export default SignupForms;